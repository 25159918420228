import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import useStyles from './styles.js';

const PlaceDetailsSummary = ({ place, setChildClicked, index }) => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.paper} onClick={() => setChildClicked(index)}>
      <Typography className={classes.typography} variant="subtitle2" gutterBottom>{place.name}</Typography>
      <img
        className={classes.pointer}
        src={place.url_photo && place.url_photo !== '' ? place.url_photo : 'https://www.elplural.com/uploads/s1/14/90/29/2/un-hombre-haciendose-un-corte-de-pelo-en-una-peluqueria-unsplash.jpeg'}
        alt={place.name}
      />
      <Rating name="read-only" className={classes.rating} size="small" value={Number(place.rating)} readOnly />
      {place.price && <Typography variant="body2" className={classes.price}>${place.price}</Typography>}
    </Paper>
  );
};

export default PlaceDetailsSummary;
