import React, { useState, useEffect } from 'react';
import { Avatar, Backdrop, Fade, List, ListItem, ListItemAvatar, ListItemText, Modal, Divider, Typography } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';

import useStyles from './styles.js';

const ReviewListModal = ({ place, open, setOpen, setShouldReload }) => {
  const classes = useStyles();
  const [reviewsList, setReviewsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setShouldReload(true);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      fetch(`${process.env.REACT_APP_SERVER_URL}/api/places/${place.id}/reviews`)
        .then((response) => response.json())
        .then((data) => {
          setReviewsList(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching reviews:', error);
          setIsLoading(false);
        });
    }
  }, [open, place.id]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2>Reviews para {place.name}</h2>
          {isLoading ? <p>Cargando reviews...</p> : (
            <List className={classes.listContainer}>
              {reviewsList.length === 0 && <p>No hay reviews disponibles para este lugar.</p>}
              {reviewsList.map((reviewDetail, index) => (
                <div key={reviewDetail.id}>
                  <ListItem alignItems="flex-start" className={classes.listItem}>
                    <ListItemAvatar>
                      <Avatar alt="Avatar" src={reviewDetail.picture_url} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={(
                        <>
                          <Typography variant="subtitle1" className={classes.userName}>
                            {reviewDetail.full_name}
                          </Typography>
                          <Rating name="read-only" value={Number(reviewDetail.rating)} readOnly size="small" />
                        </>
                      )}
                      secondary={(
                        <>
                          {/* <Typography component="span" variant="body2" className={classes.inline}>
                            {new Date(reviewDetail.created_at).toLocaleDateString()}
                          </Typography> */}
                          <Typography variant="body2">{reviewDetail.description}</Typography>
                        </>
                      )}
                    />
                  </ListItem>
                  {index !== reviewsList.length - 1 && <Divider variant="inset" component="li" />}
                </div>
              ))}
            </List>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

export default ReviewListModal;
