import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mapContainer: {
    height: '85vh',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '86vh',
      width: '100%',
      margin: '10px',
    },
  },
  markerContainer: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    '&:hover': { zIndex: 2 },
  },
  pointer: {
    cursor: 'pointer',
  },
}));
