import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '88%',
    maxWidth: '450px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    outline: 'none',
  },
  listContainer: {
    maxHeight: '300px',
    overflowY: 'auto',
    marginTop: theme.spacing(2),
  },
  listItemEven: {
    backgroundColor: '#f4f4f4',
  },
  listItemOdd: {
    backgroundColor: '#e9e9e9',
  },
}));
