/* eslint-disable consistent-return */
import axios from 'axios';

export const getPelusData = async (category, sw, ne) => {
  try {
    let data = {
      data: [],
    };
    if (category === '') {
      data = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/places`, {
        params: {
          bl_latitude: sw.lat,
          bl_longitude: sw.lng,
          tr_longitude: ne.lng,
          tr_latitude: ne.lat,
        },
      });
    } else {
      data = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/category/places`, {
        params: {
          bl_latitude: sw.lat,
          bl_longitude: sw.lng,
          tr_longitude: ne.lng,
          tr_latitude: ne.lat,
          category,
        },
      });
    }

    return data.data;
  } catch (error) {
    console.log(error);
  }
};
