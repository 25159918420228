/* eslint-disable consistent-return */
import axios from 'axios';

export const getUserData = async (code) => {
  try {
    let data = {
      data: {},
    };
    data = await axios.post(`${process.env.REACT_APP_SERVER_URL}/exchange_code`, {
      code,
    });

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createNewUser = async (data) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/create_user`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
