import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  formControl: {
    minWidth: 120, marginBottom: '30px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  loading: {
    height: '68vh', display: 'flex', justifyContent: 'center', alignItems: 'center',
  },
  container: {
    padding: '20px 25px 0px 25px',
  },
  list: {
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
    },
    [theme.breakpoints.up('sm')]: {
      height: '68vh',
    },
    height: '68vh',
    overflow: 'auto',
  },
}));
