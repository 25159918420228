import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  listGrid: {
    overflowY: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: '0px !important',
      order: 2,
    },
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  mapGrid: {
    overflowY: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: '0px !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      order: 1,
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      order: 2,
    },
  },
  '.MuiGrid-spacing-xs-3': {
    margin: '0px !important',
  },
}));
