import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '88%',
    maxWidth: '550px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    outline: 'none',
  },
  listContainer: {
    maxHeight: '400px',
    overflowY: 'auto',
    marginTop: theme.spacing(1),
  },
  listItem: {
    paddingBottom: theme.spacing(1),
  },
  userName: {
    fontWeight: 'bold',
  },
  inline: {
    display: 'inline',
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));
