import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
    height: '60%',
    margin: 'auto',
  },
  photo: {
    width: '100%',
    height: 'auto',
    maxHeight: 500,
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: '4px',
    maxWidth: '500px',
    margin: 'auto',
  },
  loadingText: {
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
}));
