import { Backdrop, Fade, List, ListItem, ListItemText, Modal, Divider } from '@material-ui/core';
import { React, useState, useEffect } from 'react';

import useStyles from './styles.js';
import { formatPrice } from '../../../utils/utils.js';

const PriceListModal = ({ place, open, setOpen, setShouldReload }) => {
  const classes = useStyles();
  const [pricesList, setPricesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setShouldReload(true);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      fetch(`${process.env.REACT_APP_SERVER_URL}/api/places/${place.id}/prices`)
        .then((response) => response.json())
        .then((data) => {
          setPricesList(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching prices:', error);
          setIsLoading(false);
        });
    }
  }, [open, place.id]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2>Precios para {place.name}</h2>
          {isLoading ? <p>Cargando precios...</p> : (
            <div className={classes.listContainer}>
              {pricesList.length === 0 && <p>No hay precios disponibles para este lugar.</p>}
              <List>
                {pricesList.map((priceDetail, index) => (
                  <div key={priceDetail.id}>
                    <ListItem className={index % 2 === 0 ? classes.listItemEven : classes.listItemOdd}>
                      <ListItemText primary={priceDetail.name} secondary={formatPrice(priceDetail.price)} />
                    </ListItem>
                    {index !== pricesList.length - 1 && <Divider />}
                  </div>
                ))}
              </List>
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

export default PriceListModal;

