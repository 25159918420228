import { CssBaseline, Grid, ThemeProvider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { getPelusData } from './api/peluqueriasAPI';
import { calculateDistance } from './utils/utils';
import useStyles from './styles.js';

import Header from './components/Header/Header';
import List from './components/List/List';
import Map from './components/Map/Map';

import UserContext from './context/UserContext';
import { theme } from './theme/theme';

const App = () => {
  const classes = useStyles();

  // Map & Places
  const [category, setCategory] = useState('');
  const [coords, setCoords] = useState({
    lat: -32.957833,
    lng: -60.660873,
  });
  const [bounds, setBounds] = useState(null);

  // Places
  const [filteredPlaces, setFilteredPlaces] = useState([]);
  const [places, setPlaces] = useState([]);

  // Autocomplete
  const [autocomplete, setAutocomplete] = useState(null);

  // State
  const [childClicked, setChildClicked] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldReload, setShouldReload] = useState(true);

  // User
  const [userCoords, setUserCoords] = useState({});
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('profile'));
    if (storedData) {
      setUserData(storedData);
    }
  }, []);

  // Carga la ubicación actual del usuario
  useEffect(() => {
    setIsLoading(true);
    let isMounted = true;

    if (coords.lat === 0 && coords.lng === 0) {
      setCoords({ lat: -32.957833, lng: -60.660873 });
      setIsLoading(false);
    }

    navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
      if (isMounted) {
        setCoords({ lat: latitude, lng: longitude });
        setUserCoords({ lat: latitude, lng: longitude });
        setIsLoading(false);
      }
    });

    return () => { isMounted = false; };
  }, []);

  // Filtrar por rating
  // const [rating, setRating] = useState('');
  /* useEffect(() => {
    const filtered = places.filter((place) => Number(place.rating) > rating);

    setFilteredPlaces(filtered);
  }, [rating]); */

  // Filtrar por categoría
  useEffect(() => {
    if (bounds && shouldReload) {
      setIsLoading(true);

      getPelusData(category, bounds.sw, bounds.ne)
        .then((data) => {
          const validPlaces = data ? data.filter((place) => place.name) : [];

          let orderedPlaces = [];
          if (userCoords.lat && userCoords.lng && validPlaces) {
            orderedPlaces = validPlaces.map((place) => {
              const distance = calculateDistance(userCoords.lat, userCoords.lng, place.latitude, place.longitude);
              return { ...place, distance: distance * 1000 };
            }).sort((a, b) => a.distance - b.distance);
          } else {
            orderedPlaces = validPlaces;
          }

          setPlaces(orderedPlaces);
          setFilteredPlaces([]);
          setChildClicked(null);
          setIsLoading(false);
          // setRating('');
        });
    }
  }, [bounds, category]);

  // Autocomplete
  const onLoad = (autoC) => setAutocomplete(autoC);
  const onPlaceChanged = () => {
    const lat = autocomplete.getPlace().geometry.location.lat();
    const lng = autocomplete.getPlace().geometry.location.lng();

    setCoords({ lat, lng });
  };

  return (
    <ThemeProvider theme={theme}>
      <UserContext.Provider value={{ userData, setUserData }}>
        <CssBaseline />
        <Header onPlaceChanged={onPlaceChanged} onLoad={onLoad} />
        <Grid container spacing={3} style={{ margin: '0', width: '100%' }}>
          <Grid item xs={12} md={4} className={classes.listGrid}>
            <List
              isLoading={isLoading}
              childClicked={childClicked}
              places={filteredPlaces?.length ? filteredPlaces : places}
              category={category}
              setCategory={setCategory}
              setShouldReload={setShouldReload}
            />
          </Grid>
          <Grid item xs={12} md={8} className={classes.mapGrid}>
            <Map
              setChildClicked={setChildClicked}
              setBounds={setBounds}
              setCoords={setCoords}
              coords={coords}
              places={filteredPlaces?.length ? filteredPlaces : places}
            />
          </Grid>
        </Grid>
      </UserContext.Provider>
    </ThemeProvider>
  );
};

export default App;
