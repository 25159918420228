// Header.js
import { AppBar, Box, IconButton, Toolbar, Typography, useMediaQuery } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import InputBase from '@material-ui/core/InputBase';
import Slide from '@material-ui/core/Slide';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@react-google-maps/api';
import React, { useContext, useRef, useState } from 'react';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { LoginSocialGoogle } from 'reactjs-social-login';

import { createNewUser, getUserData } from '../../api/userAPI.js';

import UserContext from '../../context/UserContext.js';

import RegisterButton from './RegisterPlaceIcon.js';
import useStyles from './styles.js';

const Header = ({ onPlaceChanged, onLoad }) => {
  const mobile = useMediaQuery('(max-width:1100px)');
  const classes = useStyles();
  const autocompleteInputRef = useRef();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { userData, setUserData } = useContext(UserContext);

  const getUserInfo = (data) => {
    getUserData(data.code).then((res) => {
      if (res.data?.email) {
        createNewUser(res.data).then((resp) => {
          if (resp.status === 200) console.log('Usuario ya existe');
          else console.log('Usuario creado: ', resp);
        }).catch((err) => {
          console.log('Error al crear el usuario: ', err);
        });
        setUserData(res.data);
        localStorage.setItem('profile', JSON.stringify(res.data));
      } else {
        console.log('Error al obtener el usuario: ', res);
      }
    });
  };

  return (
    <AppBar position="static">
      <Toolbar className={classes.toolbar}>
        <img
          src={mobile ? 'images/L_logo.png' : 'images/logo_320x80.png'}
          alt="Pelu App"
          height={80}
          style={{ marginLeft: '1.5em', padding: '0.5em 0' }}
        />
        <Box display="flex">
          <IconButton
            className={classes.searchIcon}
            color="inherit"
            style={{ display: (isSearchOpen && mobile) ? 'none' : 'block' }}
            onClick={() => window.open('https://wa.me/3416552804?text=Hola!%20Me%20gustaría%20añadir%20mi%20negocio%20en%20la%20aplicación.', '_blank')}
            aria-label="add business"
          >
            <RegisterButton />
          </IconButton>
          <IconButton color="inherit" onClick={() => setIsSearchOpen((prev) => !prev)} className={classes.searchIcon} aria-label="search">
            <SearchIcon />
          </IconButton>
          <Collapse in={isSearchOpen} timeout="auto" unmountOnExit>
            <Slide direction="left" in={isSearchOpen} mountOnEnter unmountOnExit>
              <div style={{ transition: 'opacity 1s', opacity: isSearchOpen ? 1 : 0 }}>
                <Autocomplete
                  onLoad={onLoad}
                  onPlaceChanged={onPlaceChanged}
                  fields={['address_components', 'geometry', 'icon', 'name']}
                  bounds={{
                    east: -60.6211,
                    west: -60.7813,
                    north: -32.8844,
                    south: -33.0410,
                  }}
                  strictBounds
                >
                  <div className={classes.search} style={{ marginTop: userData ? '0.75em' : '1em' }}>
                    <InputBase
                      placeholder="Por dónde buscamos?"
                      inputRef={autocompleteInputRef}
                      classes={{ root: classes.inputRoot, input: classes.inputInput }}
                    />
                  </div>
                </Autocomplete>

              </div>
            </Slide>
          </Collapse>
          {userData ? (
            <Typography variant="h6" className={classes.userHello}>
              Hola {userData.name}
            </Typography>
          ) : (
            <LoginSocialGoogle
              client_id="74595058909-ck0l7uou578hkpet55supi9p5mktd0lr.apps.googleusercontent.com"
              scope="openid profile email"
              discoveryDocs="claims_supported"
              access_type="offline"
              onResolve={({ provider, data }) => {
                getUserInfo(data);
              }}
              onReject={(err) => {
                console.log(err);
              }}
            >
              <GoogleLoginButton className={classes.loginButton}>
                <Box display={{ xs: 'none', sm: 'block' }}>
                  <span>Iniciar sesión</span>
                </Box>
              </GoogleLoginButton>
            </LoginSocialGoogle>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
