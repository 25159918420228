import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import ClockIcon from '@material-ui/icons/Schedule';
import Rating from '@material-ui/lab/Rating';
import React, { useState } from 'react';

import CONSTANTS from '../../models/CONSTANTS.json';
import { formatPrice, metersToBlocks } from '../../utils/utils.js';
import ReviewListModal from '../Modals/ReviewListModal/ReviewListModal.js';
import DescriptionModal from '../Modals/DescriptionModal/DescriptionModal.js';
import PriceListModal from '../Modals/PriceModal/PriceModal.js';
import RatingModal from '../Modals/RatingModal/RatingModal.js';
import useStyles from './styles.js';
import CarouselModal from '../Modals/CarouselModal/CarouselModal';

const PlaceDetails = ({ place, selected, refProp, shouldScroll, setShouldReload }) => {
  if (selected && shouldScroll) refProp?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [priceModalOpen, setPriceModalOpen] = useState(false);
  const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [carouselModalOpen, setCarouselModalOpen] = useState(false);
  const hasPrice = place.price !== undefined && place.price !== '' && place.price !== null;

  function openInNewWindow(inputUrl) {
    let url = inputUrl;
    if (!url.match(/^[a-zA-Z]+:\/\//)) {
      url = `http://${url}`;
    }
    window.open(url, '_blank');
  }

  const handleReviewOpen = () => {
    setShouldReload(false);
    setReviewModalOpen(true);
  };

  const handlePriceOpen = () => {
    setShouldReload(false);
    setPriceModalOpen(true);
  };

  const handleOpen = () => {
    setShouldReload(false);
    setOpen(true);
  };

  const handleDescriptionOpen = () => {
    setShouldReload(false);
    setDescriptionModalOpen(true);
  };

  const handleCarouselOpen = () => {
    setShouldReload(false);
    setCarouselModalOpen(true);
  };

  return (
    <Card elevation={6}>
      <CardMedia
        style={{ height: 250 }}
        image={place.url_photo ? place.url_photo : 'https://www.elplural.com/uploads/s1/14/90/29/2/un-hombre-haciendose-un-corte-de-pelo-en-una-peluqueria-unsplash.jpeg'}
        title={place.name}
        onClick={handleCarouselOpen}
        className={classes.clickable}
      />
      <CardContent>
        {place.distance && (
          <Typography variant="body2" color="textSecondary" className={classes.spacing}>
            {`Estás a ${metersToBlocks(place.distance)} cuadra${metersToBlocks(place.distance) > 1 ? 's' : ''}`}
          </Typography>
        )}
        <Typography gutterBottom variant="h5" onClick={handleDescriptionOpen} className={classes.clickable}>
          {place.name}
        </Typography>
        <hr style={{ margin: '0.8em 0' }} />
        <Box display="flex" justifyContent="space-between">
          <Rating name="read-only" value={Number(place.rating)} readOnly onClick={handleReviewOpen} className={classes.clickable} />
          {place.num_reviews && place.num_reviews !== null ? (
            <Typography
              component="legend"
              className={`${classes.legend} ${classes.clickable}`}
              onClick={handleReviewOpen}
            >
              {place.num_reviews} review{place.num_reviews > 1 && 's'} »
            </Typography>
          ) : (
            <Typography component="legend" className={classes.legend}>
              No hay reviews
            </Typography>
          )}
        </Box>
        <Button size="small" color="secondary" onClick={handleOpen} style={{ marginTop: '0.5em' }}>
          Calificar
        </Button>
        <hr style={{ margin: '0.8em 0' }} />
        <RatingModal
          place={place}
          open={open}
          setOpen={setOpen}
          setShouldReload={setShouldReload}
        />
        <DescriptionModal
          place={place}
          open={descriptionModalOpen}
          setOpen={setDescriptionModalOpen}
          setShouldReload={setShouldReload}
        />
        <PriceListModal place={place} open={priceModalOpen} setOpen={setPriceModalOpen} setShouldReload={setShouldReload} />
        <ReviewListModal place={place} open={reviewModalOpen} setOpen={setReviewModalOpen} setShouldReload={setShouldReload} />
        <CarouselModal place={place} open={carouselModalOpen} setOpen={setCarouselModalOpen} />
        <Box display="flex" justifyContent="space-between">
          <Typography component="legend" className={classes.legend}>{CONSTANTS.SERVICE_DESCRIPTIONS[place.category]}</Typography>
          <Box display="flex" alignItems="center">
            <Typography gutterBottom variant="subtitle1" onClick={handlePriceOpen} style={{ cursor: 'pointer', marginRight: '8px', fontSize: hasPrice ? '1.3em' : '1em' }}>
              {
                !hasPrice ? 'Desconocido' : <b>{formatPrice(place.price)}</b>
              }
            </Typography>
          </Box>
        </Box>
        {
          hasPrice && (
            <Typography variant="body2" color="primary" className={classes.morePrices} onClick={handlePriceOpen}>Más precios</Typography>
          )
        }
        {place.address && (
          <div className={classes.detailItem}>
            <Typography gutterBottom variant="body2" color="textSecondary" className={classes.subtitle}>
              <LocationOnIcon />{place.address}
            </Typography>
          </div>
        )}
        {place.open_time && place.open_time !== '00:00' && (
          <div className={place.open_time2 ? '' : classes.detailItem}>
            <Typography variant="body2" color="textSecondary" className={classes.spacing}>
              <ClockIcon />
              {place.open_time2 ? 'Primer turno' : 'Horario'}: {place.open_time} - {place.close_time}
            </Typography>
          </div>
        )}
        {place.open_time2 && place.open_time2 !== '00:00' && (
          <div className={classes.detailItem}>
            <Typography variant="body2" color="textSecondary" className={classes.spacing}>
              <span style={{ flex: 1 }} />
              Segundo turno: {place.open_time2} - {place.close_time2}
            </Typography>
          </div>
        )}
        {place.phone && (
          <div className={classes.detailItem}>
            <Typography variant="body2" color="textSecondary" className={classes.spacing}>
              <PhoneIcon /> {place.phone}
            </Typography>
          </div>
        )}
      </CardContent>
      <CardActions>
        {place.website && place.website !== null && place.website !== '' ? (
          <Button size="small" color="primary" onClick={() => openInNewWindow(place.website)}>
            Web
          </Button>
        ) : (
          <Button size="small" color="primary" disabled>
            Web
          </Button>
        )}
        {place.social && place.social !== null && place.social !== '' ? (
          <Button size="small" color="primary" onClick={() => openInNewWindow(place.social)}>
            Red social
          </Button>
        ) : (
          <Button size="small" color="primary" disabled>
            Red social
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default PlaceDetails;
