import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  chip: {
    margin: '5px 5px 5px 0',
  },
  subtitle: {
    display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px',
  },
  spacing: {
    display: 'flex', alignItems: 'center', justifyContent: 'space-between',
  },
  detailItem: {
    marginBottom: '10px',
  },
  clickable: {
    cursor: 'pointer',
  },
  morePrices: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  legend: {
    fontSize: '0.9rem',
    color: '#999',
  },
}));
