import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100px',
    borderRadius: '15px', // esquinas redondeadas
    boxShadow: '0px 0px 20px rgba(0,0,0,0.1)', // sombra suave
  },
  '@global': {
    '.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3': {
      [theme.breakpoints.down('sm')]: {
        width: 'auto !important',
      },
    },
  },
  rating: {
    marginTop: '5px',
  },
  pointer: {
    cursor: 'pointer',
    width: '100%',
    borderRadius: '10px',
  },
  typography: {
    textAlign: 'center',
  },
  price: {
    textAlign: 'center',
    marginTop: '5px',
  },
}));
