export const formatPrice = (price) => {
  // Ensure price is a string
  let priceStr = String(price);

  // If there is already a comma in the string, replace it with a point
  if (priceStr.includes(',')) {
      priceStr = priceStr.replace(',', '.');
  }

  // Split the integer and decimal parts
  const [integerPart, decimalPart] = priceStr.split('.');

  // Format the integer part by adding a dot three digits from the right
  const integerFormatted = `${integerPart.slice(0, -3)}.${integerPart.slice(-3)}`;

  // If there's a decimal part, format it and append it to the integer part
  const finalOutput = decimalPart ? `${integerFormatted},${decimalPart}` : integerFormatted;

  return `$${finalOutput}`;
};

export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radio de la Tierra en kilómetros
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
            + Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180))
            * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d; // Retorna la distancia en kilómetros
};

export const metersToBlocks = (meters) => {
  const blocks = meters / 100; // suponiendo que 100m es una cuadra
  if (blocks < 0.75) return 1;
  if (blocks >= 0.75 && blocks < 1.25) return 1.5;
  return Math.round(blocks);
};
