import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import React, { createRef, useEffect, useState } from 'react';

import PlaceDetails from '../PlaceDetails/PlaceDetails';
import CONSTANTS from '../../models/CONSTANTS.json';
import useStyles from './styles.js';

const List = ({ places, category, setCategory, childClicked, isLoading, setShouldReload }) => {
  const [elRefs, setElRefs] = useState([]);
  const classes = useStyles();

  const hotCategories = [
    'Barberías',
    'Peluquerías',
  ];

  const activeCategories = [
    'Barberías',
    'Peluquerías',
    'Centros de Estética',
    'Tatuajes y Piercings',
  ];

  useEffect(() => {
    setElRefs((refs) => Array(places?.length).fill().map((_, i) => refs[i] || createRef()));
  }, [places]);

  return (
    <div className={classes.container}>
      <Typography variant="h4">Lugares cerca tuyo</Typography>
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress size="5rem" />
        </div>
      ) : (
        <>
          <FormControl className={classes.formControl}>
            <InputLabel id="type">Tipo</InputLabel>
            <Select id="type" value={category} onChange={(e) => setCategory(e.target.value)}>
              <MenuItem value="">Todos</MenuItem>
              {
                CONSTANTS.CATEGORIES
                  .sort((a, b) => activeCategories.includes(b) - activeCategories.includes(a))
                  .map((c, i) => (
                    <MenuItem
                      key={i}
                      value={c}
                      disabled={!activeCategories.includes(c)}
                    >
                      {c} {hotCategories.includes(c) && '🔥'}{!activeCategories.includes(c) && '(Próximamente)'}
                    </MenuItem>
                  ))
              }
            </Select>
          </FormControl>
          {/* <FormControl className={classes.formControl}>
            <InputLabel id="rating">Rating</InputLabel>
            <Select id="rating" value={rating} onChange={(e) => setRating(e.target.value)}>
              <MenuItem value="">Todo</MenuItem>
              <MenuItem value="3">Arriba de 3.0</MenuItem>
              <MenuItem value="4">Arriba de 4.0</MenuItem>
              <MenuItem value="4.5">Arriba de 4.5</MenuItem>
            </Select>
          </FormControl> */}
          <Grid container spacing={3} className={classes.list}>
            {
              places?.length === 0 ? (
                <Grid item xs={12}>
                  <Typography variant="subtitle1" align="center">No hay {category} disponibles</Typography>
                  <Typography variant="subtitle2" align="center">Intenta cambiar los filtros o seleccionar otra zona</Typography>
                </Grid>
              ) : (
                places?.map((place, i) => (
                  <Grid ref={elRefs[i]} key={i} item xs={12}>
                    <PlaceDetails
                      selected={Number(childClicked) === i}
                      refProp={elRefs[i]}
                      place={place}
                      shouldScroll={childClicked !== null}
                      setShouldReload={setShouldReload}
                    />
                  </Grid>
                ))
              )
            }
          </Grid>
        </>
      )}
    </div>
  );
};

export default List;
