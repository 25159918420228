import { useMediaQuery } from '@material-ui/core';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import GoogleMapReact from 'google-map-react';
import React from 'react';

import mapStyles from './mapStyles';
import useStyles from './styles.js';
import PlaceDetailsSummary from '../PlaceDetailsSummary/PlaceDetailsSummary';

const Map = ({ coords, places, setCoords, setBounds, setChildClicked }) => {
  const matches = useMediaQuery('(min-width:600px)');
  const classes = useStyles();

  // State para manejar el lugar seleccionado
  const [selectedPlace, setSelectedPlace] = React.useState(null);

  return (
    <div className={classes.mapContainer}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          v: '3.30',
        }}
        defaultCenter={coords}
        center={coords}
        defaultZoom={14}
        margin={[50, 50, 50, 50]}
        options={{ disableDefaultUI: true, zoomControl: true, styles: mapStyles }}
        onChange={(e) => {
          setCoords({ lat: e.center.lat, lng: e.center.lng });
          setBounds({ ne: e.marginBounds.ne, sw: e.marginBounds.sw });
        }}
        onChildClick={(child) => {
          if (matches) setChildClicked(child);
        }}
        onClick={() => setSelectedPlace(null)}
      >
        {places?.length && places.map((place, i) => (
          <div
            className={classes.markerContainer}
            /* eslint-disable-next-line react/no-unknown-property */
            lat={Number(place.latitude)}
            /* eslint-disable-next-line react/no-unknown-property */
            lng={Number(place.longitude)}
            key={i}
          >
            {(selectedPlace && selectedPlace.id === place.id)
              ? (
                <PlaceDetailsSummary place={selectedPlace} setChildClicked={setChildClicked} index={i} />
              )
              : <LocationOnOutlinedIcon color="secondary" className={classes.pointer} fontSize={matches ? 'medium' : 'large'} onClick={() => setSelectedPlace(place)} />}
          </div>
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
