import Modal from '@material-ui/core/Modal';
import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useStyles from './styles.js';

const CarouselModal = ({ open, setOpen, place }) => {
  const classes = useStyles();
  const [photosList, setPhotosList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      fetch(`${process.env.REACT_APP_SERVER_URL}/api/places/${place.id}/photos`)
        .then((response) => response.json())
        .then((data) => {
          setPhotosList(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching photos:', error);
          setIsLoading(false);
        });
    }
  }, [open, place.id]);

  const handleClose = () => {
    setOpen(false);
  };

  const renderContent = () => (
    <>
      {
        !isLoading && photosList.length > 0 ? (
          <Carousel
            autoPlay
            infiniteLoop
            showStatus={false}
            showIndicators
            showThumbs
            showArrows
          >
            {
              photosList.map((photo, index) => (
                <div key={photo.id}>
                  <img src={photo.url_photo} alt={`Foto ${index}`} className={classes.photo} />
                </div>
              ))
            }
          </Carousel>
        ) : (
          <div className={classes.modalContent}>
            {isLoading && <p className={classes.loadingText}>Cargando fotos...</p>}
            {!isLoading && photosList.length === 0 && <p className={classes.loadingText}>No hay fotos disponibles</p>}
          </div>
        )
      }
    </>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
    >
      {renderContent()}
    </Modal>
  );
};

export default CarouselModal;
