import { Backdrop, Button, Fade, IconButton, Modal, Snackbar, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import { React, useContext, useState } from 'react';
import Alert from '@material-ui/lab/Alert';

import UserContext from '../../../context/UserContext';

import useStyles from './styles.js';

const RatingModal = ({ place, open, setOpen, setShouldReload }) => {
  const classes = useStyles();

  const { userData } = useContext(UserContext);

  const [ratingValue, setRatingValue] = useState(null);
  const [reviewDescription, setReviewDescription] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleClose = () => {
    setShouldReload(true);
    setOpen(false);
    setRatingValue(null);
    setReviewDescription('');
  };

  const handleReviewSubmit = () => {
    if (!userData) {
      setSnackbarMessage('Por favor inicia sesión para calificar.');
      setSnackbarOpen(true);
      handleClose();
      return;
    }

    const reviewData = {
      rating: ratingValue,
      description: reviewDescription,
      user_id: userData.sub,
    };

    if (!reviewData.description || reviewData.rating == null) {
      setSnackbarMessage('La descripción y la calificación son requeridas.');
      setSnackbarOpen(true);
      handleClose();
      return;
    }

    fetch(`${process.env.REACT_APP_SERVER_URL}/api/places/${place.id}/reviews`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reviewData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return response.text().then((text) => { throw new Error(text || response.statusText); });
      })
      .then((data) => {
        console.log('Success:', data);
        // Aquí puedes mostrar una snackbar de éxito, si lo deseas
      })
      .catch((error) => {
        console.error('Error:', error);
        const errorMessage = error.message.toLowerCase();

        if (errorMessage.includes('already reviewed')) {
          setSnackbarMessage('Parece que ya has calificado este lugar. Por favor, intenta con otro.');
        } else {
          setSnackbarMessage(`Error al subir la revisión: ${error.message}. Por favor, inténtalo de nuevo.`);
        }
        setSnackbarOpen(true);
      });

    setRatingValue(null);
    setReviewDescription('');
    handleClose();
  };

  return (
    <>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Calificar {place.name}</h2>
            <Rating
              name="simple-controlled"
              value={ratingValue}
              onChange={(event, newValue) => {
                setRatingValue(newValue);
              }}
            />

            {!userData && <p>Por favor inicia sesión para calificar.</p>}
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Review"
              type="text"
              fullWidth
              value={reviewDescription}
              onChange={(event) => {
                setReviewDescription(event.target.value);
              }}
            />
            <Button onClick={handleReviewSubmit} className={classes.submitButton} color="secondary" disabled={!userData}>
              Submit
            </Button>
          </div>
        </Fade>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </>
  );
};

export default RatingModal;
