import { Backdrop, Fade, Modal, Typography, Button } from '@material-ui/core';
import { React } from 'react';

import useStyles from './styles.js';

const DescriptionModal = ({ place, open, setOpen, setShouldReload }) => {
  const classes = useStyles();

  const handleClose = () => {
    setShouldReload(true);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Typography variant="h5" component="h2">
            {place.name}
          </Typography>
          <Typography variant="subtitle1" component="p" className={classes.descriptionText}>
            {place.description || 'No hay descripción disponible para este lugar.'}
          </Typography>
          <Button onClick={handleClose} color="primary" className={classes.closeButton}>
            Cerrar
          </Button>
        </div>
      </Fade>
    </Modal>
  );
};

export default DescriptionModal;
